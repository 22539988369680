/**=====================
    03.  Card CSS Start
==========================**/
//Card css
.card {
  margin-bottom: $card-margin-bottom;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: $card-border-radious;
  box-shadow: $card-box-shadow;

  &:hover {
    box-shadow: $card-hover-box-shadow;
    transition: all 0.3s ease;
  }

  .card-header {
    background-color: $card-header-bg-color;
    padding: $card-padding;
    border-bottom: $card-border-color;
    border-top-left-radius: $card-border-radious;
    border-top-right-radius: $card-border-radious;
    position: relative;

    &.card-no-border {
      border-bottom: none !important;
    }

    h5 {
      &:not(.mb-0),
      &:not(.m-0) {
        margin-bottom: 0;
        // text-transform: $card-header-font-transform;
        text-transform: none;
      }
    }

    > span {
      font-size: $card-header-span-size;
      color: $card-header-span-color;
      margin-top: 5px;
      display: block;
      letter-spacing: 1px;
    }

    .card-header-right {
      border-radius: 0 0 0 7px;
      right: 35px;
      top: 24px;
      display: inline-block;
      float: right;
      padding: 8px 0;
      position: absolute;
      background-color: $white;
      z-index: 1;

      .card-option {
        text-align: right;
        width: 35px;
        height: 20px;
        overflow: hidden;
        transition: 0.3s ease-in-out;

        li {
          display: inline-block;

          &:first-child {
            i {
              transition: 1s;
              font-size: 16px;
              color: var(--theme-deafult);

              &.icofont {
                color: unset;
              }
            }
          }
        }
      }

      i {
        margin: 0 5px;
        cursor: pointer;
        color: $dark-color;
        line-height: 20px;

        &.icofont-refresh {
          font-size: 13px;
        }
      }
    }
  }

  .card-body {
    padding: $card-padding;
    background-color: $card-body-bg-color;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
  }

  .card-footer {
    background-color: $card-footer-bg-color;
    border-top: $card-border-color;
    padding: $card-padding;
    border-bottom-left-radius: $card-border-radious;
    border-bottom-right-radius: $card-border-radious;
  }

  &.card-load {
    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 8;
      align-items: center;
      justify-content: center;

      i {
        margin: 0 auto;
        color: var(--theme-deafult);
        font-size: 20px;
      }
    }
  }

  &.full-card {
    position: fixed;
    top: 0;
    z-index: 99999;
    box-shadow: none;
    right: 0;
    border-radius: 0;
    border: 1px solid $light-semi-gray;
    width: calc(100vw - 12px);
    height: calc(100vh);

    .card-body {
      overflow: auto;
    }
  }
}
.page-body-wrapper {
  .card {
    .sub-title {
      font-family: $font-rubik, $font-serif;
      font-weight: normal;
      color: $theme-body-sub-title-color;
    }
  }
}

.user-item:hover {
  color: $success-color;
}

.card-absolute {
  margin-top: 20px;

  .card-header {
    position: absolute;
    top: -20px;
    left: 15px;
    border-radius: 0.25rem;
    padding: 10px 15px;

    h5 {
      font-size: 17px;
    }
  }

  .card-body {
    margin-top: 10px;
  }
}

.card-header {
  .border-tab {
    margin-bottom: -13px;
  }
}

.custom-card {
  overflow: hidden;
  padding: 30px;
  .card-header {
    padding: 0;

    img {
      border-radius: 50%;
      margin-top: -100px;
      transform: scale(1.5);
    }
  }

  .card-profile {
    text-align: center;

    img {
      height: 110px;
      padding: 7px;
      background-color: $white;
      z-index: 1;
      position: relative;
    }
  }

  .card-social {
    text-align: center;

    li {
      display: inline-block;
      padding: 15px 0;
      &:last-child {
        a {
          margin-right: 0;
        }
      }
      a {
        padding: 0;
        margin-right: 15px;
        color: rgb(188, 198, 222);
        font-size: 16px;
        transition: all 0.3s ease;

        &:hover {
          color: var(--theme-deafult);
          transition: all 0.3s ease;
        }
      }
    }
  }

  .profile-details {
    h6 {
      margin-bottom: 30px;
      margin-top: 10px;
      color: $theme-body-sub-title-color;
      font-size: 14px;
    }
  }

  .card-footer {
    padding: 0;

    > div {
      + div {
        border-left: 1px solid $light-semi-gray;
      }

      padding: 15px;
      text-align: center;

      h3 {
        margin-bottom: 0;
        font-size: 24px;
      }

      h6 {
        font-size: 14px;
        color: $theme-body-sub-title-color;
      }
      h5 {
        font-size: 16px;
        margin-bottom: 0;
      }
      i {
        font-size: 24px;
        display: inline-block;
        margin-bottom: 15px;
      }

      .m-b-card {
        margin-bottom: 10px;
      }
    }
  }
}

.card {
  & .form-control:focus {
    border-color: #86b7fe !important;
    outline: 0;
    box-shadow: none !important;
  }
  & .form-control input:focus {
    
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05) !important;
  }
}

.dropdown {
  cursor: pointer;
}

.total-user {
  // width: 340px;
}

// .input-number input::-webkit-outer-spin-button,
// .input-number input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
// }

// .input-number input[type='number'] {
//   border: none !important;
//   -moz-appearance: textfield;
// }
.input-number {
  width: 90px;
  
}
.input-number input {
  padding-block: 1px;
  border: none !important;
  font-size: 24px;
  font-weight: 500;
}
.input-number:focus {
  border: none !important;
}

.total-user svg {
  width: 24px;
  height: 24px;
}
.card .filter {
  width: 75%;
}
@media (min-width: 768px) {
  .card .filter {
    width: 250px;
  }
}
.rdt_Table {
  font-size: 16px !important;
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
// }

.social {
  &-profile {
    text-align: center;
    background-image: url(../../images/dashboard-5/profile-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    .card-body {
      padding-left: 20px;
      padding-right: 20px;
      @media (max-width: 1399px) {
        padding: 23px 15px;
      }
    }
  }
  &-details {
    margin-top: 15px;
    h5 {
      a {
        color: var(--body-font-color);
      }
    }
    .social-follow {
      column-count: 3;
      column-gap: 30px;
      margin-top: 25px;
      @media (max-width: 1399px) {
        column-gap: 23px;
      }
      li {
        position: relative;
        + li {
          &::before {
            position: absolute;
            content: '';
            width: 1px;
            height: 14px;
            background-color: var(--recent-dashed-border);
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            [dir='rtl'] & {
              left: unset;
              right: -15px;
            }
          }
        }
      }
    }
    .card-social {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 15px;
    }
  }
  &-img-wrap {
    background: linear-gradient(253deg, var(--theme-deafult) 11.51%, rgba(115, 102, 255, 0) 82.07%);
    display: inline-block;
    border-radius: 100%;
    padding: 2px;
    position: relative;
    .edit-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      svg {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 2px solid var(--white);
        background: var(--white);
      }
    }
  }
  &-img {
    padding: 5px;
    background: var(--white);
    border-radius: 100%;
    img {
      height: 68px;
      border-radius: 100%;
    }
  }
}

/**=====================
    03. Card CSS End
==========================**/
