.rdt_Table {
    .rdt_TableCol[data-column-id='1'],
    .rdt_TableCell[data-column-id='1'] {
        width: auto !important;
        flex: 0 0 46px !important;
        min-width: 46px !important;
        padding-inline: 0;
    }

    .rdt_TableCol[data-column-id='6'],
    .rdt_TableCell[data-column-id='6'] {
        width: 12rem !important;
        flex: none !important;
    }
}